<template>
  <b-button v-ripple.400="rippleColor" :variant="variant" v-bind="$attrs" v-on="$listeners">
    <slot />
  </b-button>
</template>

<script>
import Ripple from 'vue-ripple-directive';
import { BButton } from 'bootstrap-vue';

export default {
  name: 'BaseButton',
  directives: {
    Ripple,
  },
  components: {
    BButton,
  },
  props: {
    variant: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    rippleColor() {
      switch (this.variant) {
        case 'primary':
        case 'secondary':
        case 'success':
        case 'danger':
        case 'warning':
        case 'info':
        case 'dark': {
          return 'rgba(255, 255, 255, 0.15)';
        }
        case 'outline-secondary':
        case 'flat-secondary': {
          return 'rgba(186, 191, 199, 0.15)';
        }
        case 'outline-success':
        case 'flat-success': {
          return 'rgba(40, 199, 111, 0.15)';
        }
        case 'outline-danger':
        case 'flat-danger': {
          return 'rgba(234, 84, 85, 0.15)';
        }
        case 'outline-warning':
        case 'flat-warning': {
          return 'rgba(255, 159, 67, 0.15)';
        }
        case 'outline-primary':
        case 'flat-primary':
        case 'outline-info':
        case 'flat-info': {
          return 'rgba(0, 207, 232, 0.15)';
        }
        case 'outline-dark':
        case 'flat-dark': {
          return 'rgba(30, 30, 30, 0.15)';
        }
        default:
          return 'rgba(255, 255, 255, 0.15)';
      }
    },
  },
};
</script>
