<template>
  <b-card>
    <b-card-title class="d-flex justify-content-between">
      <div>
        {{ $t('Partner') + ` ${name}` }}
        <!--      <b-divider class="mx-4" vertical></b-divider>-->
        <base-button
          v-if="id && (allowImport === 1 || allowImport === true)"
          variant="outline-primary"
          class="ml-2"
          @click="openDialogFile"
        >
          {{ $t('Import data for this partner') }}
        </base-button>
        <!--      <v-spacer />-->
      </div>
      <div>
        <base-button :to="{ name: constRouter.PARTNER.name }" variant="primary">
          <feather-icon icon="CornerUpLeftIcon" />
          {{ $t('Back') }}
        </base-button>
        <base-button variant="outline-primary" class="ml-1" @click="duplicatePartner">
          <feather-icon icon="CopyIcon" class="mr-50" />
          <span class="align-middle"> {{ $t('Duplicate') }}</span>
        </base-button>
        <base-button
          :to="{
            name: constRouter.PARTNER_EDIT.name,
            params: { id: partnerId },
          }"
          variant="outline-primary"
          class="ml-1"
        >
          <feather-icon icon="Edit2Icon" class="mr-50" />
          <span class="align-middle"> {{ $t('Edit') }}</span>
        </base-button>
      </div>
    </b-card-title>
    <b-card-text>
      <h3 class="text--primary pt-3 text-center">
        {{ tableTitle }}
        <!--        <v-tooltip bottom class="no-transition">-->
        <!--          <template v-slot:activator="{ on, attrs }">-->
        <!--            <v-icon v-bind="attrs" v-on="on"> mdi-help-circle-outline </v-icon>-->
        <!--          </template>-->
        <!--          <span>-->
        <!--            {{ $t('Create a map to map the data in the excel file to the database') }}-->
        <!--          </span>-->
        <!--        </v-tooltip>-->
      </h3>
      <div class="text--primary text-center">
        ({{ $t('Data start row') }}: <b>{{ startRow }}</b
        >)
      </div>
      <b-row>
        <b-col cols="6" class="my-2">
          <b-form-group :label="$t('Search')" label-for="partnerName">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="partnerName"
                :value="search"
                :placeholder="$t('Search')"
                class="form-control-merge"
                @input="filterPartner"
              />
              <b-input-group-append is-text>
                <feather-icon class="cursor-pointer" icon="SearchIcon" />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <div v-if="isChoseStructure && !loading" class="design-mapping-field">
        <b-row>
          <b-col v-for="(aMap, index) in mapping" :key="index" cols="3">
            <b-card outlined>
              <b-card-title class="mapping-sup-title text-body-2 font-weight-bold pa-2 pb-0">
                <!--                <v-tooltip bottom class="no-transition">-->
                <!--                  <template v-slot:activator="{ on, attrs }">-->
                <div class="ellipsesText" style="display: block; max-width: unset">
                  {{ aMap.header }}
                </div>
                <!--                  </template>-->
                <!--                  <span>{{ aMap.header }}</span>-->
                <!--                </v-tooltip>-->
              </b-card-title>
              <b-card-text class="mapping-content pa-2 pt-0">
                <div class="">
                  <span class="">{{ $t('Object') }}: </span>
                  <span class="font-weight-bolder">
                    {{ aMap.tableShow }}
                  </span>
                </div>
                <div class="">
                  <span class="">{{ $t('Property') }}:</span>
                  <span v-if="aMap.column" class="ellipsesText font-weight-bolder">
                    {{ aMap.columnShow }}
                  </span>
                  <span v-else class="font-weight-bolder">
                    {{ $t(aMap.label) }}
                  </span>
                </div>
                <div class="d-flex justify-content-between">
                  <div class="type">
                    <span class="type-title">{{ $t('Type') }}: </span>
                    <b-badge class="profile-badge" :variant="`light-${colorType(aMap.type)}`">
                      {{ $t(aMap.type) }}
                    </b-badge>
                  </div>
                  <!--                <b-row align="center" justify="end" class="pr-2">-->
                  <div>
                    <span class="require-title">{{ $t('Require') }}: </span>
                    <feather-icon
                      v-if="aMap.require === 1"
                      size="20"
                      icon="CheckIcon"
                      class="text-success"
                    />
                    <feather-icon v-else size="20" icon="XIcon" class="text-danger" />
                  </div>
                  <!--                </b-row>-->
                </div>

                <!--                                </v-list-item>-->
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <div class="text-center">
        <b-spinner
          v-if="loading"
          variant="primary"
          style="width: 3rem; height: 3rem"
          class="mr-1"
          label="Large Spinner"
        />
      </div>
      <div v-if="noResult && !loading" class="text-center">
        {{ $t('No matching records found') }}
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BSpinner,
  BFormInput,
  BBadge,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue';
import { mapActions } from 'vuex';
import * as LocalStorage from '@/common/localStorage';
import { filters } from '@/common/utils';
import BaseButton from '@/components/base/BaseButton.vue';
import { showPartner } from '@/apis/apiPartner';
import constRouter from '@/constants/constRouter';

export default {
  name: 'PartnerShow',
  components: {
    BaseButton,
    BCard,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BSpinner,
    BFormInput,
    BBadge,
    BInputGroup,
    BInputGroupAppend,
  },
  // title() {
  //   return this.$t('show {resource}', { resource: this.$t('partner') });
  // },

  data() {
    return {
      constRouter,
      isChoseStructure: false,
      file: null,
      tableBodyData: null,
      header: [],
      headers: [
        {
          text: this.$t('Header and label'),
          value: 'label',
          width: 200,
        },
        {
          value: 'direction1',
          width: 100,
          sortable: false,
          align: 'center',
        },
        {
          text: this.$t('Object'),
          value: 'table',
        },
        {
          value: 'direction2',
          width: 100,
          sortable: false,
          align: 'center',
        },
        {
          text: this.$t('Property'),
          value: 'column',
        },
        {
          text: this.$t('Type'),
          value: 'type',
        },
        {
          text: this.$t('Require'),
          value: 'require',
          align: 'center',
        },
      ],
      list: null,
      options: {
        page: 1,
        itemsPerPage: -1,
      },
      noResult: false,
      loading: false,
      isLoadedData: false,

      col: [],
      data: [],
      id: undefined,
      allowImport: undefined,
      name: '',
      code: '',
      search: '',
      startRow: '',
      types: ['text', 'number', 'date'],
      listErrOfField: [],
      listRequired: [],
      mappingFieldData: [],
      mappingFieldStructure: {},
      tableAndColTemp: {},
      selectedHeader: '',
      selectedField: '',
      table: [],
      mapping: [],
      mappingTemp: [],
      // debounce search
      debounce: null,
      mappingDefault: [
        {
          header: undefined,
          table: undefined,
          column: undefined,
          type: 'text',
          required: false,
        },
      ],
      tableNameValues: [],
      fields: [],
      tableHead: [
        'Excel',
        this.$t('Object'),
        this.$t('Property'),
        this.$t('Type'),
        this.$t('Require'),
        this.$t('Delete'),
      ],
      partnerId: undefined,
    };
  },

  computed: {
    tableTitle() {
      if (this.isChoseStructure) {
        return this.$t('{resource} import structure', {
          resource: this.$t('partner'),
        });
      }
      return this.$t('There is no {resource} import structure', {
        resource: this.$t('partner'),
      });
    },
  },

  mounted() {
    // show api show
    this.showPartner();
  },

  methods: {
    openDialogFile() {
      // open file dialog import
      // this.setStateFileChooserDialog(true);
      this.$router.push({
        name: constRouter.IMPORT_STORE.name,
        query: { 'import-partner-id': this.id },
      });
    },

    colorType(type) {
      switch (type) {
        case 'text': {
          return 'secondary';
        }
        case 'numeric': {
          return 'primary';
        }
        case 'date': {
          return 'info';
        }
        case 'phone': {
          return 'danger';
        }
        case 'address': {
          return 'success';
        }
        default: {
          return 'secondary';
        }
      }
    },

    changeLoadData(status) {
      this.isLoadedData = status;
    },

    filterPartner(searchKey) {
      // turn on loading
      this.loading = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        if (searchKey == null || searchKey.trim() === '') {
          this.mapping = this.mappingTemp;
          this.noResult = false;
        } else {
          const resultFilter = filters(this.mappingTemp, searchKey);
          this.mapping = resultFilter;
          this.noResult = !resultFilter;
        }
        // turn of loading
        this.loading = false;
      }, 400);
    },

    showPartner() {
      // start loading
      this.loading = true;
      this.isLoadedData = true;
      const id = +this.$route.params.id;
      if (!Number.isNaN(id)) {
        this.partnerId = id;
        showPartner(id)
          .then((res) => {
            // this.fileLoadingBtn = false;
            // set data
            if (res) {
              this.setData4UI(res.data.data);
              // rename title
              document.title = `${this.$t('{resource}', {
                resource: this.$t('Partner'),
              })} ${this.name} | HORUS`;
            }
          })
          .catch((err) => {
            console.log(err);
            // turn of loading
            this.loading = false;
          });
      }
    },

    setData4UI(data) {
      console.log(data);
      this.id = data.id;
      this.name = data.name;
      this.allowImport = data.allow_import;
      this.code = data.code;
      this.startRow = data.start_row;
      if (data.mapping) {
        // show mapping
        this.isChoseStructure = true;
        setTimeout(() => {
          let { mapping } = data;
          mapping = mapping.map((aMap) => {
            const column = this.$t(`${aMap.table}.${aMap.column}`).includes('.')
              ? aMap.column
              : this.$t(`${aMap.table}.${aMap.column}`);
            return {
              ...aMap,
              tableShow: this.$t(aMap.table) ? this.$t(aMap.table) : aMap.table,
              columnShow: column,
            };
          });

          this.mapping = mapping;
          this.mappingTemp = mapping;

          // turn of loading
          this.loading = false;
        });
      } else {
        // turn of loading
        this.loading = false;
      }
    },

    duplicatePartner() {
      const { id, name, code, allowImport, startRow, mapping } = this;
      const dataStore = {
        id,
        name: `${this.$t('Copy of')} ${name}`,
        code,
        allowImport,
        startRow,
        mapping,
      };
      LocalStorage.setStorePartnerToken(dataStore);
      this.$router.push({
        name: 'partners.store',
        query: { 'partner-id': this.id },
      });
    },
  },
};
</script>

<style scoped lang="scss">
/* table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
} */

/* td:not(:last-child), th { */
/* border: 1px solid #dddddd; */
/* text-align: left; */
/* padding-top: 16px !important; */
/* } */

/* tr:nth-child(even) {
  background-color: #dddddd;
} */
.design-mapping-field {
  .mapping-header {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .mapping-content {
    font-size: 12px;

    .item {
      min-height: unset;
      height: 20px;

      .column {
        display: flex;
        flex-direction: row;

        .column-name {
          max-width: 203px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
}
</style>
